*,
::after,
::before {
	margin: 0px;
	padding: 0px;
	box-sizing: border-box;
	font-family: 'Lexend', sans-serif !important;
}

html {
	font-size: 10px;
}

body:has(.stop-scroll-x) {
	overflow: hidden;
}

body {
	font-family: 'Lexend', sans-serif;
}

.error-message {
	color: #ff4d4f;
	font-size: 14px;
}

.required-color {
	color: red;
}

.pointer-event-none {
	pointer-events: none !important;
}

.bg-white {
	background-color: #fff !important;
}

.pointer:hover {
	cursor: pointer;
}

.pointer-disabled {
	cursor: not-allowed;
}

.primary-button {
	background-color: #286fd1;
	color: #fff;
	outline: none;
	border: none;
}

.primary-button:hover {
	background-color: #286fd1 !important;
	color: #fff !important;
	outline: none !important;
	border: none !important;
}

.secondary-button {
	background-color: #fff;
	color: #a2a7ad;
	outline: none;
	border: 1px solid #a2a7ad;
}

.secondary-button:hover {
	background-color: #fff !important;
	color: #a2a7ad !important;
	outline: none !important;
	border: 1px solid #a2a7ad !important;
}

.remove-button {
	background-color: red;
	color: #fff;
	outline: none;
	border: none;
}

.remove-button:hover {
	background-color: red !important;
	color: #fff !important;
	outline: none !important;
	border: none !important;
}

.text-color {
	color: #286fd1;
}
.text-color:hover {
	color: #286fd1;
}
.ant-picker-dropdown {
	z-index: 9999999 !important;
}

.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
	color: #286fd1 !important;
}
.ant-tabs .ant-tabs-tab {
	padding: 0px !important;
}

.disabled-text {
	opacity: 0.4;
}

.default-sub-total {
	position: sticky;
	bottom: 5.6rem;
	left: 0;
	z-index: 999;
	outline: 1px solid rgb(156, 156, 156);
	font-weight: 500;
}

.sub-total {
	position: sticky;
	bottom: 11rem;
	left: 0;
	z-index: 999;
	outline: 1px solid rgb(156, 156, 156);
	font-weight: 500;
}

.outside-adjust {
	position: sticky;
	bottom: 5.6rem;
	left: 0;
	z-index: 999;
	outline: 1px solid rgb(156, 156, 156);
	font-weight: 500;
}

.total {
	position: sticky;
	bottom: 0;
	left: 0;
	z-index: 999;
	outline: 1px solid rgb(156, 156, 156);
	font-weight: 600;
}

.ant-table-wrapper .ant-table-thead > tr > td {
	background: #fff !important;
}

.ant-pagination-total-text {
	position: absolute;
	left: 0;
}

/* .ant-table-tbody > tr > td {
	padding: 10px !important;
} */

.ant-table-wrapper .ant-table-pagination.ant-pagination {
	margin: 10px 0px !important;
}

@font-face {
	font-family: 'Lexend';
	src: url('/public/assets/fonts/Lexend-Regular.woff') format('woff');
	font-weight: 400;
	font-display: swap;
}

@font-face {
	font-family: 'Lexend';
	src: url('/public/assets/fonts/Lexend-Medium.woff') format('woff');
	font-weight: 500;
	font-display: swap;
}

@font-face {
	font-family: 'Lexend';
	src: url('/public/assets/fonts/Lexend-SemiBold.woff') format('woff');
	font-weight: 600;
	font-display: swap;
}

@font-face {
	font-family: 'Lexend';
	src: url('/public/assets/fonts/Lexend-Bold.woff') format('woff');
	font-weight: 700;
	font-display: swap;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
	-webkit-appearance: none;
}

input[type='number'] {
	-moz-appearance: textfield;
}

::-webkit-scrollbar {
	width: 5px;
	height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
	background: #f1f1f1;
	border-radius: 12px;
}

/* Handle */
::-webkit-scrollbar-thumb {
	/* background: rgb(52, 71, 53); */
	background: #888;
	border-radius: 12px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #555;
}
.apiKey_popup .ant-modal-content {
	padding: 0 !important;
}

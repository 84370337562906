@import '../../../scss/mixin.scss';

.login-body {
	&__top {
		&--title {
			font-weight: 700;
			font-size: 3.4rem;
			margin-bottom: 1.2rem;
			@include mq('desktop') {
				margin-bottom: 2.2rem;
			}
		}
		&--description {
			& > p {
				font-size: 17px;
				color: #425465;
				margin-bottom: 2.4rem;
				@include mq('desktop') {
					margin-bottom: 3.3rem;
				}
			}
		}
	}
	&__remberme-forgot {
		display: flex;
		align-items: center;
		&--forgot-password {
			font-size: 16px;
			margin-bottom: 2.5rem;
			font-weight: 500;
			color: #ff0000;
			width: max-content;
			margin-left: auto;
			&:hover {
				cursor: pointer;
			}
		}
	}

	&__account-text {
		font-size: 16px;
		font-weight: 500;
		display: flex;
		align-items: center;

		&:hover {
			cursor: pointer;
		}
		&--text {
			margin-right: 1rem;
		}
		&--link {
			height: 25px;
			border-bottom: 2px solid #286fd1;
		}
	}
	&__end {
		&--button {
			width: 100%;
			font-size: 2rem !important;
			background: #286fd1;
			height: 5rem !important;
			margin-bottom: 2.5rem;
			&:hover {
				background: #286fd1 !important;
			}
		}
	}
}

.ant-tabs .ant-tabs-tab .ant-tabs-tab-btn {
	font-size: 20px !important;
	font-weight: 500;
}

.ant-tabs-nav {
	padding-left: 0% !important;
}

.ant-tabs-tab:hover {
	color: #000 !important;
}

.ant-tabs-nav-list {
	gap: 40px;
}

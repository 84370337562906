.view-more {
	color: blue;

	&:hover {
		cursor: pointer;
	}
}

.actions {
	display: flex;
	gap: 1rem;
}

.profile-modal__header {
	padding: 1.1rem 3rem !important;
	align-items: center;
	width: 100%;
	min-height: 6rem !important;
	justify-content: space-between;
}

.profile-modal__body {
	padding: 1rem 3rem !important;
	color: #27272e !important;
	font-weight: 500;

	.fields {
		padding: 1rem !important;
	}

	.ant-select {
		width: 100% !important;
	}

	.ant-select-selector {
		line-height: normal;
	}
}

.profile_modal_footer {
	padding: 2rem 3rem !important;

	.save {
		display: flex;
		align-items: center;
		justify-content: center;
		background: #286fd1;
		font-size: 1.8rem;
		color: #fff;
		font-weight: 600;
		border: none;
		cursor: pointer;
		width: 10.5rem;
		min-height: 4.5rem;

		&:hover {
			color: #fff !important;
			background: #286fd1 !important;
		}
	}

	.cancel {
		display: flex;
		align-items: center;
		justify-content: center;
		background: #fff;
		border: 2px solid #e3e4e7;
		box-shadow: none;
		font-size: 1.8rem;
		color: #a2a7ad;
		font-weight: 600;
		cursor: pointer;
		width: 10.5rem;
		min-height: 4.5rem;

		&:hover {
			border-color: #e3e4e7 !important;
			background: #fff !important;
			color: #a2a7ad !important;
		}
	}
}

hr {
	height: 1px;
	background-color: #ccc;
	border: none;
}

.ant-modal-close {
	margin-top: 2rem;
	margin-right: 2.1rem;
}

.userDetailsTitle {
	font-size: 2rem !important;
}

.profile-form-label {
	margin-bottom: 10px;
	display: block;
}

.image_hubspot {
	max-width: 36rem !important;
	height: 6rem !important;
	margin-bottom: 2rem;
	margin-top: 2rem;
}

.center {
	display: flex;
	justify-content: center;
}

.apiKey_popup {
	.ant-modal-content {
		padding: 0 !important;
	}
}

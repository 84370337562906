.sales-report {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 2rem 0px 1rem 0px;

	&__date {
		display: flex;
		gap: 2rem;
	}
}

.toast-container-custom {
	z-index: 999999999 !important;
}

.toast-container-custom .go2072408551 {
	max-width: fit-content !important;
}

.dynamic-table-new {
	max-width: calc(100vw - 260px) !important;
}

.splash__image {
	padding: 30px;
	opacity: 0;
	animation-name: fadeIn, pulse;
	animation-duration: 2s, 2s;
	animation-delay: 0s, 0.5s;
	animation-fill-mode: both;
	animation-iteration-count: 1, infinite;
	animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

.splash__image--exit {
	animation-name: zoomOutUp;
	animation-duration: 0.6s;
}

.sales-order {
	// display: flex;
	// justify-content: space-between;
	// align-items: center;
	// margin: 2rem 0px;

	&__date {
		display: flex;
		gap: 2rem;
	}
}
.no-commission {
	display: flex;
	justify-content: space-between;
	align-items: center;
	// gap: 8px;
}

.assign-button {
	background-color: #a2a7ad;
	color: #a2a7ad;
	outline: none;
	border: 1px solid #a2a7ad;
}

.assign-button:hover {
	border-color: #d9d9d9;
	color: #00000040;
	background: #0000000a;
	box-shadow: none;
}

.global-constant {
	width: 50rem;
	&__title {
		display: flex !important;
		align-items: center !important;
		&--input {
			width: 12rem !important;
		}
	}
}

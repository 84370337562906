.modal-header {
	justify-content: space-between;
	padding: 1rem;
	&_title {
		font-size: 2rem;
		font-weight: 500;
	}

	&_close {
		font-weight: 500;
	}
}

.modal-footer {
	display: flex;
	justify-content: center;
	gap: 3rem;
}

.modal-body {
	padding: 1rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	gap: 2rem;
	&_salary {
		display: flex;

		align-items: center;
		width: 100%;
		gap: 2rem;
		// margin: 0rem 1rem;
	}
	&_plan {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		gap: 2rem;
		// margin: 1rem 1rem;

		&-gross {
			display: flex;
			gap: 2rem;
			align-items: center;
		}

		&-retention {
			display: flex;
			gap: 10rem;
			align-items: center;
			justify-content: space-between;
			margin-bottom: 1rem;

			&--period {
				color: #286fd1;
			}
		}

		&-new {
			display: flex;
			justify-content: space-between;
			align-items: start;
			width: 100%;
			gap: 2rem;
			// margin: 2rem 2rem;
		}
	}
	&_product {
		label {
			display: flex;
			gap: 2rem;
		}
		span {
			font-size: 1.7rem;
			font-weight: 400;
		}
	}
}

.main-layout {
	display: flex;
}

.switch-div {
	font-size: 1.5rem;
	display: flex;
	gap: 2rem;
	justify-content: end;
	margin-bottom: 2rem;
}

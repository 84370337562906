.total {
	position: sticky;
	bottom: 0;
	left: 0;
	z-index: 999;
	outline: 1px solid rgb(156, 156, 156);
	font-weight: 600;
}
.sales-report {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 2rem 0px;

	&__date {
		display: flex;
		gap: 2rem;
	}
}

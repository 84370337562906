.integration {
	&__card {
		width: 30rem;

		&--button {
			width: 100%;
			height: 4rem;
			font-size: 1.6rem;
		}
		&--body {
			display: flex;
			height: 120px;
			flex-direction: column;
			justify-content: space-between;
		}
	}
}

.modal-header {
	justify-content: space-between;
	padding: 1.5rem;
	border-bottom: 1px solid #286fd1;
	&_title {
		font-size: 2rem;
		font-weight: 500;
	}

	&_close {
		font-weight: 500;
	}
}

.modal-body {
	padding: 1.5rem;
	padding-bottom: 0rem;
	border-bottom: 1px solid #286fd1;
	&_plan {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		margin: 2rem 0rem;
	}
}
.total {
	font-weight: bold;
}

.modal-footer {
	padding: 2rem;
	padding-top: 0rem;
	margin: 0px !important;
	display: flex;
	justify-content: space-between;
}

.note {
	font-size: 12px;
	color: rgb(117, 117, 117);
	font-weight: 0;
}

.side-drawer-form {
	&__inputs {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		// column-gap: 3rem;
		// row-gap: 3rem;
		& > div {
			padding-left: 3.636rem;
			padding-right: 3.636rem;
		}
	}
	&__role {
		font-weight: 600 !important;

		&--label {
			margin-bottom: 10px;
			display: flex;
			align-items: center;
			// gap: 0.8rem;

			font-size: 1.6rem;
			font-weight: 600 !important;
			color: #27272e;
		}
		&--select {
			font-size: 1.6rem;
			font-weight: 600;
			color: #27272e;
			width: 100%;
		}
	}
	&__buttons {
		display: flex;
		align-items: center;
		gap: 2rem;
		// margin-top: 38rem;
		padding-left: 3.636rem;
		padding-right: 3.636rem;

		&--save {
			display: flex;
			align-items: center;
			justify-content: center;
			background: #286fd1;
			font-size: 1.8rem;
			color: #fff;
			font-weight: 600;
			border: none;
			cursor: pointer;
			width: 10.5rem;
			min-height: 4.5rem;

			&:hover {
				color: #fff !important;
				background: #286fd1 !important;
			}
		}
		&--cancel {
			display: flex;
			align-items: center;
			justify-content: center;
			background: #fff;
			font-size: 1.8rem;
			color: #a2a7ad;
			font-weight: 600;
			cursor: pointer;
			width: 10.5rem;
			min-height: 4.5rem;
			border: 2px solid #e3e4e7;
			&:hover {
				border-color: #e3e4e7 !important;
				background: #fff !important;
				color: #a2a7ad !important;
			}
		}
	}
}

.input-icon {
	margin-bottom: 2.5rem;
	&__title {
		margin-bottom: 10px;
		display: flex;
		align-items: center;
		gap: 0.8rem;
		&--svg {
			display: flex;
			align-items: center;
		}
		&--label {
			font-size: 1.6rem !important;
			font-weight: 600;
			color: #27272e;
		}
	}
	&__form {
		&--input {
			border: 1px solid #d2d4d8 !important;
			&:active,
			&:focus,
			&:hover {
				box-shadow: none !important;
				border: 1px solid #d2d4d8 !important;
			}
		}
	}
}
.phoneError {
	color: #ff4d4f !important;
}

.modal-header {
	justify-content: space-between;

	&_title {
		font-size: 2rem;
		font-weight: 500;
	}

	&_close {
		font-weight: 500;
	}
}

.modal-footer {
	display: flex;
	justify-content: center;
	gap: 3rem;
}

.modal-body {
	margin: 2rem 0rem;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	// justify-content: center;
	gap: 2rem;
	height: 300px !important;
	&_list {
		display: flex;
		flex-direction: column;
		gap: 1rem;
		height: 150px;
		overflow-y: scroll;
	}

	&_product {
		label {
			display: flex;
			gap: 2rem;
		}
		span {
			font-size: 1.7rem;
			font-weight: 400;
		}
	}
}

.global-layout {
	height: 100vh;

	&__body {
		display: flex;
		height: calc(100vh - 74px);
		&--body {
			overflow: auto;
			width: calc(100vw - 254.2px);
			background: #f3f5f7;
			padding: 2.1rem 3rem 1rem 3rem;
		}
	}
}
